import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import {
  AboutWrapper,
  AboutPageTitle,
} from './style';

import styles from './index.module.css';


const User = props => (
  <div>
      <div className={styles.user}>
        <img src={props.titleimg} className={styles.titleimg} alt="" />
        <div className={styles.faqblock}>
            <div>
              質問
            <p className={props.question}>{props.question}</p>
            </div>
            <div>
              回答
            <p className={styles.answer}>{props.answer}</p>
            </div>
        </div>
        <Link to={props.url}>
          関連資料
        </Link>
      </div>
  </div>
  )


interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      titleimg: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      
      <AboutPageTitle>
        <h2>よくあるご質問とその回答</h2>
        <p>
          このページでは、よくある質問とその回答をご紹介します。
        </p>
      </AboutPageTitle>

      <User
        url="/sdgs"
        question="GoogleEarthに、地理院地図を貼り付けて使うことはできますか？"
        titleimg="https://source.unsplash.com/featured/?earth"
        answer="可能です。方法は、、、、"
      />
      <User
        url="/sdgs"
        question="このサイトを授業で使っても良いですか？"
        titleimg="https://source.unsplash.com/featured/?lesson"
        answer="はい、お使いいただけます"
      />
      <User
        url="/sdgs"
        theme="高校地理探究"
        question="分析をより深めるためにはどうしたら良いですか？"
        titleimg="https://source.unsplash.com/featured/?analyze"
        answer="メッシュ統計データは様々な機関で公開されています。当サイトのリンク集がヒントとしてお使いいただけます。"
      />
      <User
        url="/sdgs"
        theme="出典"
        question="夏休みの課題のテーマに使っても良いですか？"
        titleimg="https://source.unsplash.com/featured/?report"
        answer="はい、お使いいただけます。"
      />
      <User
        url="/top"
        theme="出典"
        question="プレビュー画面の地図の出典を教えてください。"
        titleimg="https://source.unsplash.com/featured/?map"
        answer="国土地理院から提供されているものです。"
      />



    </AboutWrapper>
  );
};

export default About;
